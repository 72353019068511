import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property vallue called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({ fill }) {
  return (
        <svg width="110px" height="90px" version="1.1" viewBox="0 0 73.923 30.628" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-32.193 -59.884)" filter="url(#filter904)">
            <path d="m88.309 74.723a4.5951 4.4583 0 0 1-4.5951 4.4583 4.5951 4.4583 0 0 1-4.5951-4.4583 4.5951 4.4583 0 0 1 4.5951-4.4583 4.5951 4.4583 0 0 1 4.5951 4.4583" fill="red" opacity=".7" style={{'paint-order': 'normal'}}/>
          </g>
          <g transform="translate(-32.193 -59.884)">
            <path d="m44.009 63.518-10.949 15.557h17.613z" fill="#2fc4b2" opacity=".95" style={{'paint-order': "markers fill stroke"}}/>
            <path d="m41.382 60.718-8.3216 18.357h72.223-72.246z" fill="#e6e620" stroke={fill} stroke-dashoffset="2.8917" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.689" style={{'paint-order':"fill markers stroke"}}/>
          </g>
          <g transform="translate(-32.193 -59.884)">
            <path d="m41.382 60.718 7.7603 28.961z" fill="none" stroke={fill} stroke-dashoffset="1.978" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.689"/>
          </g>
          <g transform="translate(-32.193 -59.884)">
            <g transform="matrix(.97998 0 0 .97998 2.1208 2.1228)" fill={fill} stroke-width=".26999" aria-label="Nityam">
              <path d="m52.635 77.373h-1.254l-3.4864-7.6757v7.6757h-1.13v-9.7221h1.2609l3.5002 7.6068v-7.6068h1.1093z"/>
              <path d="m58.629 67.478q0.30317 0 0.51676 0.22049 0.2136 0.2136 0.2136 0.51676 0 0.31695-0.2136 0.53054-0.20671 0.2136-0.51676 0.2136-0.2825 0-0.50298-0.22049-0.2136-0.22738-0.2136-0.52365t0.2136-0.51676q0.22049-0.22049 0.50298-0.22049zm0.52365 9.8943h-1.0749v-6.2494h-2.1153v-0.93018h3.1902z"/>
              <path d="m69.474 77.049q-1.0818 0.49609-2.1291 0.49609-1.9224 0-1.9224-2.0326v-4.389h-1.378v-0.93018h1.378v-1.9637h1.0473v1.9637h2.4391v0.93018h-2.4391v4.1066q0 0.65457 0.28939 1.0197 0.28939 0.35829 0.78548 0.35829 0.88194 0 1.7432-0.39274z"/>
              <path d="m78.19 70.193-2.7216 7.1796q-0.59256 1.5847-1.1782 2.2118-0.57878 0.62701-1.3229 0.62701-0.66146 0-1.3091-0.24116l0.17914-0.86127q0.52366 0.16536 0.93707 0.16536 0.57878 0 0.95084-0.44786t0.75103-1.4676l-2.6941-7.1658h1.1713l2.0326 5.7257 2.0326-5.7257z"/>
              <path d="m86.507 77.373h-1.068q-0.13091-0.43408-0.13091-1.1576-0.9164 1.3436-2.4942 1.3436-0.79926 0-1.4125-0.565-0.60634-0.57189-0.60634-1.4676 0-0.53054 0.2136-0.93706t0.62701-0.68213q0.41341-0.2825 1.006-0.44097 0.59256-0.16536 1.4056-0.22738l1.2058-0.09646v-0.34451q0-1.8466-1.7225-1.8466-0.40652 0-1.0335 0.17914-0.62012 0.17225-1.068 0.41341l-0.2825-0.7717q1.4676-0.75103 2.7285-0.75103 2.4529 0 2.4529 2.5976v3.0868q0 0.93706 0.17914 1.6674zm-1.254-2.1291v-1.3022q-1.4538 0.13091-1.9982 0.24805-0.53743 0.11024-0.93706 0.4203-0.39274 0.31006-0.39274 0.8406 0 0.48231 0.33762 0.77859t0.86127 0.29628q0.57188 0 1.1369-0.33762 0.565-0.34451 0.99219-0.94396z"/>
              <path d="m95.354 77.373h-1.0749v-4.7129q0-1.7088-0.6339-1.7088-0.32384 0-0.71658 0.59256-0.38585 0.58567-0.38585 0.64768v5.1814h-1.068v-4.7129q0-1.7088-0.64079-1.7088-0.31695 0-0.70969 0.59256-0.38585 0.58567-0.38585 0.64768v5.1814h-1.0749v-7.1796h1.0335v1.0818q0.30317-0.57189 0.71658-0.9095 0.4203-0.34451 0.83371-0.34451 0.43408 0 0.77859 0.34451 0.34451 0.33762 0.47542 0.9095 0.30317-0.57189 0.72347-0.9095 0.4203-0.34451 0.82682-0.34451 1.3022 0 1.3022 2.3289z"/>
            </g>
          </g>
        </svg>
  );
}